import React from "react";
import LayoutHome from "../layout/LayoutHome";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { donationServiceConfig } = getMandatoryEnv(["donationServiceConfig"]);
const GeneralDonationUSA = () => {
  return (
    <LayoutHome seoTitle="General Donation USA" isIframe>
      <iframe
        title="General Donation USA 2023"
        src={donationServiceConfig.heartfulnessorgDonationLink}
        allowFullScreen
        border={0}
        style={{ height: "100%", width: "100%", border: "0" }}
      />
    </LayoutHome>
  );
};

export default GeneralDonationUSA;
